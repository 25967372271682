import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Arial', sans-serif;
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff ;
        background-repeat: no-repeat;
        background-size: 100% 40%;
        overflow-x: hidden;
        background-position: 0 0, center;
        font-family: 'Arial', sans-serif;
    }

    a:hover {
        color: #18216d;
    }
    
    input,
    textarea {
        background: none;
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #FFF 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #FFFFFF;
    }


    h1 {
        font-weight: 800;
        font-size: 45px;   
    }

    a {
        text-decoration: none;
        outline: none;
        color: rgba(0, 0, 0, 0.85);

        :hover {
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .ant-slider-handle.ant-tooltip-open {
        border-color: #1890ff;
    }
    .ant-slider-track {
        background-color: rgba(0, 0, 0, 0.65);
    }
    .ant-slider:hover .ant-slider-track {
        background-color: rgba(0, 0, 0, 0.75);
    }
    .ant-slider-handle {
        border-color: rgba(0, 0, 0, 0.65);
        :focus{
            border-color: rgba(0, 0, 0, 0.65);
        }
    }
    .ant-slider-handle.ant-tooltip-open {
        border-color: rgba(0, 0, 0, 0.75);
    }
    .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: rgba(0, 0, 0, 0.75);
    }
    .ant-picker-panel-container .ant-picker-panels {
        @media only screen and (max-width: 1024px) {
            display: block;
            width: min-content;
        }
    }
    @-webkit-keyframes fadeIn {
        from { opacity: 0; }
          to { opacity: 1; }
    }
    @keyframes fadeIn {
        from { opacity: 0; }
          to { opacity: 1; }
    }
    .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
        color: rgba(0, 0, 0, 0.85);
        border-color: #A9B2B9;
        :hover{
            border-color: #656c70;
            color: rgba(0, 0, 0, 0.85);
        }
        a {
            color: rgba(0, 0, 0, 0.85);
            :hover{
                color: rgba(0, 0, 0, 1);

            }
        }
    }
    .ant-pagination-item {
        border-color: #A9B2B9;
        :hover{
            border-color: #656c70;
        }
        a {
            color: rgba(0, 0, 0, 0.85);
            :hover{
                color: rgba(0, 0, 0, 1);

            }
        }
    }
    .ant-pagination-item-active {
        border-color: #656c70;
        :hover{
            border-color: #A9B2B9;
        }
        a {
            color: rgba(0, 0, 0, 1);
            :hover{
                color: rgba(0, 0, 0, 0.85);

            }
        }
    }
    *:focus {
        outline: none;
    }

    .ant-carousel{
        height:200px;
        width:100%;
    }
    .ant-carousel .slick-list .slick-slide{
        width: 1080px !important;
        margin-right: 100px;
        margin-left: 160px;
        @media only screen and (max-width: 1024px) {
            width: 20rem !important;
            height: 27rem !important;
            margin-right: -17rem;
            margin-left: 25rem;
        }
    }
    .ant-carousel .slick-list .slick-slide > div > div
    {
        
        @media only screen and (max-width: 1024px) {
            
            display: inline-block !important;
        }
    }
    .ant-carousel .slick-slider{
        @media only screen and (max-width: 1024px) {
            height: 37rem;
        }
    }
    .ant-carousel .slick-slider .slick-list{
        @media only screen and (max-width: 1024px) {
            height: 38rem;
        }
    }
    .ant-carousel .slick-slider .slick-track{
        @media only screen and (max-width: 1024px) {
            
            display: flex !important;
        }
    }
    .about-block-image svg {
        text-align: center;
    }
    .ant-carousel .slick-slide {
        margin-top:70px;
         height: 280px !important; 
         float:left;
         
        @media only screen and (max-width: 768px) {
            height: 180px !important; 
        }
    }
    .ant-carousel .slick-dots{
        filter: invert(50%);
        width: fit-content;
        margin: 0 auto;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #90b752;
        border-color: #90b752;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner { 
        background-color: #90B752;
        border-color: #90B752;
        
    }
    .ant-checkbox-wrapper:active .ant-checkbox-inner, .ant-checkbox:active .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner { 
        background-color: #90B752;
        border-color: #90B752;
        
    }
    .ant-menu-light .ant-menu-item-active {
        color: #21507e;
    }
    .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
        color: #21507e;
    }
    .ant-menu-submenu-selected {
        color: #21507e;
    }
    .ant-menu-light .ant-menu-submenu-active {
        color: #21507e;
    }
    .ant-carousel .slick-slide {
        height: 200px;
    }
    .ant-menu-horizontal {
        line-height: 46px;
        border: 0;
        border-bottom: 1px solid #f0f0f0;
        background: #F8F8F8 0% 0% no-repeat padding-box;
        box-shadow: none;
        margin-top:10px;
        padding-left:100px;
        border-radius: 20px 20px 0px 0px;
    }
    .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
        position: relative;
        top: 1px;
        display: inline-block;
        vertical-align: bottom;
        text-align: left;
        font: normal normal medium 30px/36px Inter;
        letter-spacing: 0px;
        color: #747C91D9;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after{
        border-bottom: 2px solid #90B752;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after{
        border-bottom: 2px solid #90B752;
    }
    .ant-menu-horizontal  .ant-menu-item-selected{
        text-align: left;
        letter-spacing: 0px;
        color: #90B752 !important;
        background: rgb(144 183 82 / 20%) 0% 0% no-repeat padding-box;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
        text-align: left;
        letter-spacing: 0px;
        color: #90B752 !important;
    }
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: #21507e;
    }
    .ant-menu-light .ant-menu-submenu-title:hover {
        color: #21507e;
    }
    .ant-menu-light .ant-menu-item:hover{
        color: #21507e;
    }
    .ant-menu-item-selected {
        color: #21507e;
    }
    .ant-btn-primary:hover, .ant-btn-primary:focus {
        color: #fff;
        border-color: rgb(33 80 126 / 68%);
        background: rgb(33 80 126 / 68%);
    }
    .ant-btn-dashed:hover, .ant-btn-dashed:focus {
        color: #21507e;
        border-color: #21507e;
        background: #fff;
    }
    .ant-btn-primary {
        color: #fff;
        border-color: #21507e;
        background: #21507e;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    }
    .ant-btn-primary:active {
        color: #fff;
        border-color: rgb(33 80 126 / 68%);
        background: rgb(33 80 126 / 68%);
    }
    .ant-menu-inline .ant-menu-item::after{
        border-right: 3px solid #21507e;
    }
    .ant-input-affix-wrapper {
        /* UI Properties */
        background: #F8F8F8 0% 0% no-repeat padding-box;
        border: 0.2px solid #CFD7ED;
        border-radius: 3px 0px 0px 3px;
        opacity: 1;
    }
    .ant-input {
        height: 43px;
        /* UI Properties */
        background: #F8F8F8 0% 0% no-repeat padding-box;
        border: 0.2px solid #CFD7ED;
        border-radius: 3px 0px 0px 3px;
        opacity: 1;
    }
    .ant-select {
        width: 220px;
        height: 43px;
        /* UI Properties */
        background: #F8F8F8 0% 0% no-repeat padding-box;
        border-radius: 3px 0px 0px 3px;
        opacity: 1;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 43px;
        /* UI Properties */
        background: #F8F8F8 0% 0% no-repeat padding-box;
        border: 0.2px solid #CFD7ED;
        opacity: 1;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-left: 0px !important;
        padding-top:5px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-left: 0px !important;
        padding-top:5px;
    }
    .ant-picker {
        width: 220px;
        height: 43px;
        /* UI Properties */
        background: #F8F8F8 0% 0% no-repeat padding-box;
        border: 0.2px solid #CFD7ED;
        opacity: 1;
    }
    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
