import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const _ = require('lodash');

// Define a type for the slice state
interface formState {
  form_options: object,
  error:object
}

// Define the initial state using that type
const initialState: formState = {
  form_options: {},
  error: {}
}


export const formSlice = createSlice({
  name: 'form',
  initialState: initialState,
  reducers: {
    cleanFormOptions: (state: any) => {
      state.form_options = {};
    },
    setFormOptions: (state: any, action: PayloadAction<object>) => {
      state.form_options = action.payload;
    },
    setError: (state: any, action: PayloadAction<object>) => {
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { cleanFormOptions, setFormOptions, setError } =
  formSlice.actions;
// You must export the reducer as follows for it to be able to be read by the form.
export default formSlice.reducer;