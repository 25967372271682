import styled from "styled-components";

export const Container = styled("div")`
  padding: 10px 5px;
`;

export const StyledInput = styled("input")`
  font-size: 0.875rem;
`;

export const Icon = styled("div")`
  height: 1.5rem;
  width: 1.5rem;
  background-color: red;
  padding: 4px;
  position: absolute;
  box-sizing:border-box;
  top:50%;
  left:2px;
  transform: translateY(-50%);
`;