import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import { PngIcon } from "../../common/PngIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  HeaderContainer
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall  to="/login/personas">
          <Span>Personas</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall to="/login/empresas">
          <Span>Empresas</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall to="/login/corredores">
          <Span>Corredores</Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <HeaderContainer>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
              <PngIcon src="logo.png" width="160px" height="45px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
        </Row>
      </HeaderContainer>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
