import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "#fff"};
  color: ${(p) => (p.color ? "#fff" : "#6776ED")};
  font-size: 1rem;
  font-weight: 700;
  border: 1px solid #edf3f5;
  border-radius: 4px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  min-width:100px;

  
  @media screen and (max-width: 1024px) {
    width:max-content !important;
    min-width:100px;
  }
  &:disabled {
    color:white !important; 
    background:gray;
    opacity:0.5;
    border: 1px solid gray;
  },
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid #fff;
  }
`;
