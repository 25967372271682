import { createAsyncThunk } from "@reduxjs/toolkit"
import categoryService from "../../services/categoryService"
const searchCategories = createAsyncThunk(
  'category/getCategories',
  async () => {
    const response = await categoryService.getAll(null)
    return response.data;
  }
)
const categoryActions = { searchCategories }
export default categoryActions;