import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const _ = require('lodash');

// Define a type for the slice state
interface ComparadorState {
  SlotA: object,
  SlotB: object,
  SlotC: object,
}

// Define the initial state using that type
const initialState: ComparadorState = {
  SlotA: {},
  SlotB: {},
  SlotC: {},
}


export const comparadorSlice = createSlice({
  name: 'comparador',
  initialState: initialState,
  reducers: {
    deleteProduct: (state: any, action: PayloadAction<any>) => {
      (Object.keys(state) as (keyof typeof state)[]).forEach((key, index) => {
        if(state[key].id === action.payload.id){
          state[key] = {};
        }
      });
    },
    setProduct: (state: any, action: PayloadAction<any>) => {
      (Object.keys(state) as (keyof typeof state)[]).every((key, index) => {
        if(_.isEmpty(state[key])){
          state[key] = action.payload;
          return false;
        }
        return true;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProduct, deleteProduct } =
  comparadorSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default comparadorSlice.reducer;