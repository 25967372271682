import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import http from "../../services/https-common";
import userActions from "../actions/userActions";
import { RootState } from "../configureStore";

export interface AppState {
  user: {
    accessToken: string;
    isAdmin: boolean;
    refreshToken: string;
    username: string;
  } | null;
  username: string;
  password: string;
  category: string;
  success: boolean;
  error: boolean;
}
const initialState: AppState = {
  user: JSON.parse((localStorage.getItem("user") as string)  === "undefined" ? "{}" : (localStorage.getItem("user") as string)),
  username: "",
  password: "",
  category: "",
  success: false,
  error: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserName(state, action: PayloadAction<AppState["username"]>) {
      state.username = action.payload;
    },
    updatePassword(state, action: PayloadAction<AppState["password"]>) {
      state.password = action.payload;
    },
    updateCategory(state, action: PayloadAction<AppState["category"]>) {
      state.category = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        userActions.login.fulfilled,
        (state, action: PayloadAction<AppState["user"]>) => {
          localStorage.setItem("user", JSON.stringify(action.payload));
          state.user = action.payload;
        }
      )
      .addCase(userActions.logout.fulfilled, (state) => {
        localStorage.removeItem("user");
        state.user = null;
        state.username = "";
        state.password = "";
        state.success = false;
        state.error = false;
      })
      .addCase(userActions.logout.rejected, (state) => {
        localStorage.removeItem("user");
        state.user = null;
        state.username = "";
        state.password = "";
        state.success = false;
        state.error = false;
      })
      .addCase(userActions.deleteUser.pending, (state) => {
        state.success = false;
        state.error = false;
      })
      .addCase(userActions.deleteUser.fulfilled, (state) => {
        state.success = true;
      })
      .addCase(userActions.deleteUser.rejected, (state) => {
        state.error = true;
      })
      .addCase(userActions.refreshToken.fulfilled, (state, action) => {
        localStorage.setItem("user", JSON.stringify(action.payload));
        state.user = action.payload as AppState["user"];
      })
      .addCase(userActions.refreshToken.rejected, (state, action) => {
        localStorage.removeItem("user");
        state.user = null;
        state.username = "";
        state.password = "";
        state.success = false;
        state.error = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { updateUserName,  updatePassword, updateCategory} =
userSlice.actions;
// You must export the reducer as follows for it to be able to be read by the category.
export default userSlice.reducer;