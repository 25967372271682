import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useAppSelector } from '../../router/hooks';
const _ = require('lodash');

type NotificationType = 'success' | 'info' | 'warning' | 'error';
const Notification: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const error = useAppSelector((state) => state.formSlice.error);
  const openNotificationWithIcon = async(type: NotificationType, message: any, title: any) => {
    await api[type]({
      message: title,
      duration: 2,
      description:
        message,
    });
  };
  useEffect(()=>{
    if(!_.isEmpty(error) && api)
      openNotificationWithIcon(error.type, error.message, error.title)
  }, [error, api])
  return (
    <>
      {contextHolder}
    </>
  );
};
export default Notification;