
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import customerService from "../../services/customerService";
import http_public from "../../services/https-common";
import userService from "../../services/userService";
import { RootState } from "../configureStore";
import { setError } from "../reducers/formReducer";
import suscribService from "../../services/suscribService";
import cancelService from "../../services/cancelService";
import dudaService from "../../services/dudaService";
import travelerService from "../../services/travelerService";
const modulePrefix = "user";

const login = createAsyncThunk(
  `${modulePrefix}/login`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    try {
      const res = await http_public.post("token/", {
        username: state.userSlice.username,
        password: state.userSlice.password,
      })
      console.log(res.data)
      return res.data
    } catch (error) {
      thunkAPI.dispatch(setError({ type: 'error', message: 'Verifique bien los datos ingresados.', title: 'Ha ocurrido un problema' }));
    }
  }
);

const logout = createAsyncThunk(
  `${modulePrefix}/logout`,
  async (_, { getState }) => {
    const state = getState() as RootState;

    const res = await http_public.post(
      `logout/`,
      {
        token: state.userSlice?.user?.refresh,
      },
      {
        headers: {
          authorization: `Bearer ${state.userSlice?.user?.access}`,
        },
      }
    );

    return res.data;
  }
);
const createUser = createAsyncThunk(
  `${modulePrefix}/createUser`,
  async ({ params }: any) => {
    const response = await userService.create(params)
    return response.data;
  }
)
const createCustomer = createAsyncThunk(
  `${modulePrefix}/createCustomer`,
  async (params: any, thunkAPI) => {
    try {
      const response = await customerService.create(params)
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setError({ error: 'error', message: error, title: 'Ha ocurrido un problema' }));
    }
  }
)
const editCustomer = createAsyncThunk(
  `${modulePrefix}/editCustomer`,
  async ({ id, params }: any, thunkAPI) => {
    try {
      const response = await customerService.update(id, params)
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setError({ error: 'error', message: error, title: 'Ha ocurrido un problema' }));
    }
  }
)
const editUser = createAsyncThunk(
  `${modulePrefix}/editUser`,
  async ({ id, params }: any) => {
    const response = await userService.update(id, params)
    return response.data;
  }
)
const deleteUser = createAsyncThunk(
  `${modulePrefix}/deleteUser`,
  async (id: number, { getState }) => {
    const state = getState() as RootState;

    const res = await http_public.delete(`users/${id}/`, {
      headers: { authorization: `Bearer ${state.userSlice.user?.access}` },
    });

    return res.data;
  }
);

const refreshToken = createAsyncThunk(
  `${modulePrefix}/refresh`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    try {
      const res = await http_public.post(`token/refresh/`, {
        refresh: state.userSlice.user?.refresh,
      });

      const newUser = {
        ...state.userSlice.user,
        access: res.data.access,
        refresh: res.data.refresh,
      };

      return newUser;
    } catch (error) {
      thunkAPI.dispatch(setError({ error: 'error', message: error, title: 'Ha ocurrido un problema' }));
    }
  }
);



const createSuscrib = createAsyncThunk(
  'public/createSuscrib',
  async (params: any, thunkAPI) => {
    try {
      const response = await suscribService.create(params)
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setError({ error: 'error', message: error, title: 'Ha ocurrido un problema' }));
    }
  }
)

const createCancel = createAsyncThunk(
  'public/createCancel',
  async (params: any, thunkAPI) => {
    try {
      const response = await cancelService.create(params)
      thunkAPI.dispatch(setError({ type: 'success', message: '', title: 'Se ha solicitado el cancelamiento del pedido' }));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setError({ error: 'error', message: error, title: 'Ha ocurrido un problema' }));
    }
  }
)

const createDuda = createAsyncThunk(
  'public/createDuda',
  async (params: any, thunkAPI) => {
    try {
      const response = await dudaService.create(params)
      thunkAPI.dispatch(setError({ type: 'success', message: '', title: 'Nos pondremos en contacto contigo brevemente' }));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setError({ error: 'error', message: error, title: 'Ha ocurrido un problema' }));
    }
  }
)

const createTraveler = createAsyncThunk(
  'public/createTraveler',
  async (params: any, thunkAPI) => {
    try {
      const response = await travelerService.create(params)
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setError({ error: 'error', message: error, title: 'Ha ocurrido un problema' }));
    }
  }
)

const userActions = { login, logout, deleteUser, refreshToken, editCustomer, editUser, createUser, createCustomer, createSuscrib, createCancel, createDuda, createTraveler }

export default userActions;