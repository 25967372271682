import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import { Provider } from 'react-redux'
import store from './store/configureStore'
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import esES from 'antd/es/locale/es_ES';

import Router from "./router";
import i18n from "./translation";
import { ConfigProvider } from "antd";

dayjs.locale('es');

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        
      <ConfigProvider locale={esES}>
        <Router />
      </ConfigProvider>
      </Provider>
    </I18nextProvider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
