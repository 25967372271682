import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { useEffect } from "react";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  Small,
  ButtonCancel,
  SocialRow,
  Copyright,
  Terms,
  InfoContainer,
  SuscribContainer
} from "./styles";
import { PngIcon } from "../../common/PngIcon";
import Input from "../../common/Input";
import { getParams, setCategory, setCategoryTitle } from "../../store/reducers/filterReducer";
import { useAppDispatch, useAppSelector } from "../../router/hooks";
import { setCategories } from "../../store/reducers/categoryReducer";
import categoryActions from "../../store/actions/categoryActions";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const dispatch = useAppDispatch()
  const categoryStore = useAppSelector(state => state.categorySlice.categories)
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };
  const scrollUp = async(e:any, category: any) => {
    console.log(e.target)
    await dispatch(setCategoryTitle(e.target.text))
    
    await dispatch(setCategory(category))
    await dispatch(getParams())
    await window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  
  const fetchData = async() =>{
    if(!!categoryStore){
      const categories = await dispatch(categoryActions.searchCategories())
      await dispatch(setCategories(categories.payload.results))
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={24} xs={24}>
              <PngIcon src="logowhite.png" width="150px" height="45px" />
            </Col>
            <Col lg={4} md={4} sm={14} xs={14}>
              <InfoContainer>
                <Title>Seguros</Title>
                {categoryStore.map((i:any) => (<Large to={`/comparar/${i.tag_name.toLowerCase()}`} onClick={(e:any) => scrollUp(e, i.tag_name.toLowerCase())}>{i.name}</Large>))}
                
              </InfoContainer>
            </Col>
            <Col lg={6} md={6} sm={14} xs={14}>
              <InfoContainer>
              <Title>Centro de Información</Title>
              <Large to="/frecuentes" left="true"  onClick={scrollUp}>
                Preguntas frecuentes
              </Large>
              <Large left="true" to="/comparar"  onClick={scrollUp}>
                Compara seguros
              </Large>
              <Large left="true" to="/acerca"  onClick={scrollUp}>
                Sobre PasGo
              </Large>
              </InfoContainer>
            </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <SuscribContainer>
                    <Small left="true" to="/suscribete">
                    ¡Suscríbete y recibe las mejores novedades!
                    </Small>
                    <Row>
                      <Input name="suscribete" placeholder="Tu email" onChange={() => {}}/>
                      <ButtonCancel name="submit" style={{'width': '200px', marginLeft:'0px'}}>
                        Suscribirme
                      </ButtonCancel>
                    </Row>
                </SuscribContainer>
              </Col>
          </Row>
          <Row justify="center">
            <Col >
              <Row justify="center">
                <Col >
                  <Row justify="center">
                    <Copyright>PasGo, compara y compra seguros © 2023 - TODOS LOS DERECHOS RESERVADOS</Copyright>
                  </Row>
                  <Terms justify="space-between" align="middle">
                    <Small left="true" to="/terminos">
                      Términos y condiciones
                    </Small>
                    
                    <Small left="true" >
                    |
                    </Small>
                    <Small left="true" to="/privacidad">
                      Privacidad
                    </Small>
                    <Small left="true" >
                    |
                    </Small>
                    <Small left="true" to="/cookies">
                      Cookies
                    </Small>
                  </Terms>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </FooterSection>
  );
};

export default withTranslation()(Footer);
