import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import publicRoutes from "./configPublic";
import privateRoutes from "./configPrivate";
import { Styles } from "../styles/styles";
import { useAppSelector } from "./hooks";
import Notification from "../common/Notification";

const Router = () => {
  const user = useAppSelector((state) => state.userSlice.user?.access);
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Notification/>
      <Switch>
        {publicRoutes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
        {privateRoutes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
