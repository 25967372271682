import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const _ = require('lodash');

// Define a type for the slice state
interface categoryState {
  categories: Array<object>
}

// Define the initial state using that type
const initialState: categoryState = {
  categories: []
}


export const categorySlice = createSlice({
  name: 'category',
  initialState: initialState,
  reducers: {
    setCategories: (state: any, action: PayloadAction<object>) => {
      state.categories = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCategories } =
  categorySlice.actions;
// You must export the reducer as follows for it to be able to be read by the category.
export default categorySlice.reducer;