const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/login", "/login/:category"],
    exact: true,
    component: "Login",
  },
  {
    path: ["/personas"],
    exact: true,
    component: "Person",
  },
  {
    path: ["/privacidad"],
    exact: true,
    component: "Privacity",
  },
  {
    path: ["/frecuentes"],
    exact: true,
    component: "Questions",
  },
  {
    path: ["/terminos"],
    exact: true,
    component: "Terms",
  },
  {
    path: ["/acerca"],
    exact: true,
    component: "About",
  },
  {
    path: ["/comparar/","/comparar/:category","/comparar/:category/:page"],
    exact: true,
    component: "Filter",
  }
];

export default routes;
