import { withTranslation } from "react-i18next";
import { Container, Icon, StyledInput } from "./styles";
import { Label } from "../TextArea/styles";
import { InputProps } from "../types";

const Input = ({ name, placeholder, onChange, t, icon = false, ...props }: any) => (
  <>
    {icon && (
                
    <Icon></Icon>
              )}
    <StyledInput
    placeholder={t(placeholder)}
    name={name}
    id={name}
    value={props.value}
    onChange={onChange}
  />
  </>
);

export default withTranslation()(Input);
