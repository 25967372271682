const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/login/:category"],
    exact: true,
    component: "Login",
  },
  {
    path: ["/personas"],
    exact: true,
    component: "Person",
  },
  {
    path: ["/privacidad"],
    exact: true,
    component: "Privacity",
  },
  {
    path: ["/frecuentes"],
    exact: true,
    component: "Questions",
  },
  {
    path: ["/terminos"],
    exact: true,
    component: "Terms",
  },
  {
    path: ["/acerca"],
    exact: true,
    component: "About",
  },
  {
    path: ["/panel/personas"],
    exact: true,
    component: "Panel/Person",
  },
  {
    path: ["/panel/empresas"],
    exact: true,
    component: "Panel/Enterprise",
  },
  {
    path: ["/panel/admin"],
    exact: true,
    component: "Panel/Admin",
  },
  {
    path: ["/panel/corredores"],
    exact: true,
    component: "Panel/Brokers",
  },
  {
    path: ["/comparar/","/comparar/:category","/comparar/:category/:page"],
    exact: true,
    component: "Filter",
  },
];

export default routes;
