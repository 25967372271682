import any from "../common/types";
import http from "./https-common";

const getAll = (payload:any) => {
  console.log(payload)
  return http.get<any>(`/categories/?${payload}`);
};

const get = (id: any) => {
  return http.get<any>(`/categories/${id}/`);
};

const create = (data: any) => {
  return http.post<any>("/categories/", data);
};

const update = (id: any, data: any) => {
  return http.put<any>(`/categories/${id}/`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/categories/${id}/`);
};

const removeAll = () => {
  return http.delete<any>(`/categories`);
};

const findByTitle = (title: string) => {
  return http.get<any>(`/categories?title=${title}`);
};

const categoryService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
};

export default categoryService;