import { combineReducers } from 'redux'
import categorySlice from './categoryReducer'
import comparadorSlice from './comparadorReducer'
import filterSlice from './filterReducer'
import storeSlice from './storeReducer'
import userSlice from './userReducer'
import formSlice from './formReducer'

const rootReducer: any = combineReducers({
  userSlice,
  filterSlice,
  categorySlice,
  comparadorSlice,
  storeSlice,
  formSlice
})
export default rootReducer