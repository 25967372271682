import styled from "styled-components";

import { StyledButton } from "../../common/Button/styles";

export const MiddleBlockSection = styled("section")`
  position: relative;
  padding: 7.5rem 0 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    padding: 5.5rem 0 3rem;
    margin: 5rem;
  }
`;

export const Content = styled("div")`
  padding: 0.75rem 0 0.75rem;
`;

export const SubContent = styled("div")`
text-align: center;
font: normal normal 600 20px/40px Inter;
letter-spacing: 0px;
color: #000000;
opacity: 1;
  padding: .75rem 0 0.75rem;
  padding-top:6rem;
  margin: 0 auto;
  padding-bottom:3rem;
  padding-left:1rem;
  padding-right:1rem;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding-left:2rem;
    padding-right:2rem;
    font-size:15px;
  }
`;

export const SubListContent = styled("div")`

max-width: 100%;
margin:0 auto;
align:center;
text-align:center;
margin-left:11rem;
@media only screen and (max-width: 768px) {
  margin-left:2rem;
  padding-right:2rem;
}
`;
export const ListContent = styled("div")`
  color:#000;
  text-align:left;
  margin:0 auto;
  padding-top:0.75rem;
  text-align: left;
  font: normal normal 600 17px/40px Inter;
  color: #000000;
  opacity: 1;
  @media only screen and (max-width: 768px) {
    font-size:10px;
    margin-top:1rem;
  }
`;

export const ButtonCancel = styled(StyledButton)`
  margin: 0 auto;
  text-align:center !important;
  height:50px;
  min-height:50px;
  min-width:100px !important;
  
  align:center !important;
  vertical-align: middle !important;
  padding-left:10px;
  padding-right:10px;
  margin-top:30px;
  margin-left:0px;
  margin-right:0px;
  background:${(p) => (p.inverse ? "#fff" : "#90b752")}; 
  background-repeat: no-repeat; 
  background-size: 100% 100%;
  color:${(p) => (!p.inverse ? "#fff" : "#90b752")}; 
  box-shadow: 0 16px 30px rgb(23 31 114 / 0%);
  border: 1px solid #90B752;
  
  @media only screen and (max-width: 1024px) {
    min-width:130px;
    margin-left:30px;
    margin-right:30px;

  }

  &:hover,
  &:active,
  &:focus {
    color:${(p) => (!p.inverse ? "#fff" : "#90b752")}; 
    border: 1px solid #90B752;
  }
  `;
export const Title = styled("div")`
  background: #90B752 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  width: 100%;
  height: 84px;  
  text-align:center;
  padding-top:18px;
  margin: 0 auto;
  position:absolute;
  top:0;
  font-size:18px;
  @media only screen and (max-width: 768px) {
    font-size:12px;
  }
`;

export const ContentWrapper = styled("div")`
  text-align:center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 40px;
  opacity: 1;
  background-repeat: no-repeat; 
  background-size: 100% 100%;
  padding-bottom:20px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
