import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import queryString from 'query-string';
const _ = require('lodash');

// Define a type for the slice state
interface storeState {
  filters: Object
}

// Define the initial state using that type
const initialState: storeState = {
  filters: {
    loading:false,
    page: 1,
    categories: [ localStorage.getItem("category") || 'viaje'],
    category_title : '',
    options: {
      order: '',
      moneda: 'CL',
      auto_reemplazo: '',
      garajemarca: '',
    },
    price: {
      min: 800,
      max: 200000
    },
    deducible: {
      min: 0,
      max: 200
    },
    params: ''
  }
}


export const filterSlice = createSlice({
  name: 'filters',
  initialState: initialState,
  reducers: {
    setLoading: (state: any, action: PayloadAction<any>) => {
      state.filters.loading = action.payload
    },
    setPage: (state: any, action: PayloadAction<any>) => {
      state.filters.page = action.payload
    },
    setCategory: (state: any, action: PayloadAction<any>) => {
      localStorage.setItem("category", action.payload);
      state.filters.categories = [action.payload]
    },
    setCategoryTitle: (state: any, action: PayloadAction<any>) => {
      state.filters.category_title = action.payload
    },
    getParams: (state: any) => {
      const query = {'page':state.filters.page, 'categories': state.filters.categories.join(','), 'order': state.filters.options.order, 'min': state.filters.price.min, 'max': state.filters.price.max, 'deducible_min': state.filters.deducible.min, 'deducible_max': state.filters.deducible.max, 'auto_reemplazo':  state.filters.auto_reemplazo, 'garajemarca': state.filters.garajemarca}
      state.filters.params = queryString.stringify(query);
    },
    deleteCategory: (state: any, action: PayloadAction<any>) => {
      state.filters.categories = state.filters.categories.filter((value:any) => value !== action.payload)
    },
    deleteOrder: (state: any, action: PayloadAction<any>) => {
      state.filters.options.order = ''
    },
    deleteMoneda: (state: any, action: PayloadAction<any>) => {
      state.filters.options.moneda = ''
    },
    deletePrice: (state: any, action: PayloadAction<any>) => {
      state.filters.price = {
        min: 800,
        max: 200000
      }
    },
    deleteDeducible: (state: any, action: PayloadAction<any>) => {
      state.filters.deducible = {
        min: 0,
        max: 200
      }
    },
    addCategory: (state: any, action: PayloadAction<any>) => {
      //state.filters.categories.push(action.payload)
      state.filters.categories = [action.payload]
    },
    addOrder: (state: any, action: PayloadAction<any>) => {
      state.filters.options.order = action.payload
    },
    addMoneda: (state: any, action: PayloadAction<any>) => {
      state.filters.options.moneda = action.payload
    },
    addPrice: (state: any, action: PayloadAction<any>) => {
      state.filters.price = action.payload
    },
    addDeducible: (state: any, action: PayloadAction<any>) => {
      state.filters.deducible = action.payload
    },

    addAutoReemplazo: (state: any, action: PayloadAction<any>) => {
      state.filters.options.auto_reemplazo = action.payload
    },
    addGarajeMarca: (state: any, action: PayloadAction<any>) => {
      state.filters.options.garajemarca = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setCategory, setCategoryTitle, setPage, getParams, deleteCategory, deleteOrder, deleteMoneda, deletePrice, addCategory, addOrder, addMoneda, addPrice, addDeducible, addAutoReemplazo, addGarajeMarca} =
  filterSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default filterSlice.reducer;