import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const _ = require('lodash');

// Define a type for the slice state
interface storeState {
  products: object
}

// Define the initial state using that type
const initialState: storeState = {
  products: {}
}


export const storeSlice = createSlice({
  name: 'store',
  initialState: initialState,
  reducers: {
    deleteProduct: (state: any, action: PayloadAction<object>) => {
      state.value += action.payload;
    },
    setProducts: (state: any, action: PayloadAction<object>) => {
      state.products = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProducts, deleteProduct } =
  storeSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default storeSlice.reducer;